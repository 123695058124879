/** ----------------------------------------
     Package
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Accordion
 ---------------------------------------- */

/**
 * The accordion class will take a
 * question and an answer class.
 * Modify at your hearts content.
 */

export class Accordion {

    /**
     * @accordion       accordion contained
     * @answer          the awnser class
     * @question        the question class
     * @init            calls the init method
     */

    constructor(settings) {
        this.holder = settings.holder;
        this.trigger = settings.trigger;
        this.target = settings.target;
        this.init();
    }

    /** ----------------------------------------
        Set Accordion
     ---------------------------------------- */

    /**
     * Will find a given element from the
     * settings and watches for a click event.
     */

    setHolder() {
        let _this = this;
        let trigger = this.holder.querySelector(this.trigger);

        trigger.addEventListener('click', (e) => {
            if(e.target.classList.contains('is-animating'))
                return;

            _this.toggleState(trigger);
            _this.checkState(trigger);
        });
    }

    /** ----------------------------------------
        Toggle State Classes
     ---------------------------------------- */

    toggleState(item) {
        item.classList.toggle('is-active');
    }

    /** ----------------------------------------
        Check Current State Class
     ---------------------------------------- */

    /**
     * Check state of clicked item and delegate
     * an open or close event.
     */

    setActive(item) {
        item.classList.toggle('is-animating');
    }

    checkState(item) {
        item.classList.contains('is-active') ? this.open(item) : this.close(item);
    }

    /** ----------------------------------------
        Open Accordion
     ---------------------------------------- */

    /**
     * Find height of answer and set it to the
     * parent holder element with a simple
     * animation.
     */

    open(item) {
        let target = this.holder.querySelector(this.target);
        let content = this.holder.querySelector(this.target);
        let height = content.firstElementChild.offsetHeight;
        this.animateOpen(target, item, height);
        this.setActive(item);
    }

    animateOpen(target, item, height) {
        const open = anime({
            targets: target,
            height: height,
            duration: height,
            easing: 'easeOutQuad'
        });

        open.finished.then(() => {
            target.style.height = 'auto';
            this.setActive(item);
        });
    }

    /** ----------------------------------------
        Close Accordion
     ---------------------------------------- */

    /**
     * Reset the height of the holder back to
     * zero with a simple animation.
     */

    close(item) {
        let target = this.holder.querySelector(this.target);
        this.animateClose(target, item);
        this.setActive(item);
    }

    animateClose(target, item) {
        const close = anime({
            targets: target,
            height: [target.offsetHeight, 0],
            duration: 500,
            easing: 'easeOutQuad'
        });

        close.finished.then(() => {
            this.setActive(item);
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        this.setHolder();
    }

}