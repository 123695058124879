/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Header
 ---------------------------------------- */

export class Header {

    constructor() {
        this.animation = {};
        this.init();
    }

    /** ----------------------------------------
         Scroll
     ---------------------------------------- */

    _scroll() {
        const image = document.querySelector('.header__image');

        if (!image) return;

        window.addEventListener('scroll', () => {
            if(window.innerWidth < 1080) return;
            const posY = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
            image.style.top = `${ posY / (window.innerHeight / 100) }px`;
        });
    }

    /** ----------------------------------------
         Animate
     ---------------------------------------- */

    _animations() {
        this.animation = anime.timeline({
            easing: 'easeOutQuad',
            autoplay: false,
            loop: false,
            duration: 1500
        });

        this.animation
            .add({
                targets: '.header__letter',
                translateY: [75, 0],
                rotate: [-5, 0],
                opacity: [0, 1],
                easing: 'easeOutExpo',
                duration: 1500,
                delay: (el, i) => 50 + 10 * i
            })
            .add({
                targets: ['.header__meta', '.header__text', '.header__logo', '.header__tag', '.header__title:not(.header__title--animated)'],
                opacity: [0, 1],
                easing: 'easeOutQuad',
                duration: 750,
                delay: (el, i) => 50 + 10 * i
            }, 250)
            .add({
                targets: ['.header__item'],
                opacity: [0, 1],
                easing: 'easeOutQuad',
                duration: 1250,
                delay: (el, i) => 100 + 125 * i
            }, 300)
            .add({
                targets: ['.header__button'],
                opacity: [0, 1],
                easing: 'easeOutQuad',
                duration: 750,
                delay: (el, i) => 50 + 10 * i
            }, 750)
            .add({
                targets: '.header__holder > *',
                height: ['110%', '120%'],
                opacity: [0, 1],
                translateY: ['-5%', '-10%'],
                easing: 'easeOutQuad',
                duration: 750
            }, 100);
    }

    _animate() {
        this.animation.play();
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        const article = document.querySelector('.header__article');
        const detail = document.querySelector('.header__detail');
        const overview = document.querySelector('.header__overview');
        const page = document.querySelector('.header__page');

        if(!article && !page && !overview && !detail) return;
        if (document.body.classList.contains('no-animation')) return;

        this._animations();
        this._animate();
        this._scroll();
    }

}
