/** ----------------------------------------
    Helpers
 ---------------------------------------- */

/**
 * The functions listed within this class
 * will help you do some basic checks and
 * consists out of common used functions
 * helpful during development.
 *
 * These functions all use well tested code
 * and are optimised for better performance.
 */

export class Helpers {

    /** ----------------------------------------
        Get Type
     ---------------------------------------- */

    getType(value) {
        if (typeof value === 'undefined') return 'undefined';
        if (typeof value === 'object' && !value) return 'null';
        return ({}).toString.call(value).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
    }

    /** ----------------------------------------
        Check Array
     ---------------------------------------- */

    isArray(value) {
        return value.constructor === Array;
    }

    /** ----------------------------------------
        Check Object
     ---------------------------------------- */

    isObject(value) {
        if (value === null) {
            return false;
        }
        return ((typeof value === 'function') || (typeof value === 'object'));
    }

    /** ----------------------------------------
        Check String
     ---------------------------------------- */

    isString(value) {
        return typeof value === 'string' || ((!!value && typeof value === 'object') && Object.prototype.toString.call(value) === '[object String]');
    }

    /** ----------------------------------------
        Check Boolean
     ---------------------------------------- */

    isBoolean(value) {
        return obj === true || obj === false || toString.call(obj) === '[object Boolean]';
    }

    /** ----------------------------------------
        Check Number
     ---------------------------------------- */

    isNumber(value) {
        return !isNaN(+n) && isFinite(n);
    }

}

/** ----------------------------------------
    Trigger Event
 ---------------------------------------- */

function triggerSelectEvent(el) {

    el.classList.add('clicked');

    let evObj = document.createEvent('MouseEvents');
    evObj.initEvent('mousedown', true, false);
    el.dispatchEvent(evObj);
}

/** ----------------------------------------
    Export Functions
 ---------------------------------------- */

export {
    triggerSelectEvent
}