/** ----------------------------------------
     Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
     Transitions
 ---------------------------------------- */

import * as animate from '@js/transitions/gallery';

/** ----------------------------------------
    Gallery
 ---------------------------------------- */

export class Gallery {

    constructor(settings) {
        this._arrows = settings.arrows;
        this._data = {};
        this._dom = {};
        this._el = settings.el;
        this._hooks = {};
        this._holder = settings.holder;
        this._caption = settings.caption;
        this._counter = settings.counter;
        this._locked = true;
        this._slides = [];
        this._hook();
        this.init();
    }

    /** ----------------------------------------
        Lock
     ---------------------------------------- */

    _lock() {
        this._locked = !this._locked;
    }

    /** ----------------------------------------
         Get Slides
     ---------------------------------------- */

    _getSlides() {
        const fadeIndex = this._selectSlides(this._dom.holder);
        if(!fadeIndex) return false;

        const slideAmount = fadeIndex.length;

        this._data['slides'] = fadeIndex;
        this._data['amount'] = slideAmount;
        this._data['current'] = 1;
        this._data['next'] = 2;
        this._data['prev'] = slideAmount;

        this._setSlides();
        return true;
    }

    _setSlides() {
        let count = 1;
        const slides = this._dom.holder.childNodes;

        [...slides].map((slide, index) => {
            if(this._data.slides.includes(index)) {
                slide.dataset.index = count;
                slide.style.opacity = parseInt(slide.dataset.index) === 1 ? 1 : 0;

                this._slides.push({
                    slide: slide,
                    index: count,
                    caption: slide.dataset.caption
                });

                count++;
            }
        });
    }

    /** ----------------------------------------
         Select Slides
     ---------------------------------------- */

    _selectSlides(el) {
        const children = [];

        if(!el.childNodes) return;

        for(let child in el.childNodes) {
            el.childNodes[child].nodeType == 1 && children.push(parseInt(child));
        }

        if(children.length <= 1) {
            this._removeArrows();
            return false;
        }

        return children;
    }

    /** ----------------------------------------
        Get Arrows
     ---------------------------------------- */

    _getArrows() {
        const next = this._dom.el.querySelector(this._arrows.next);
        const prev = this._dom.el.querySelector(this._arrows.prev);

        this._dom['next'] = next;
        this._dom['prev'] = prev;
    }

    _removeArrows() {
        this._hooks.on('init', () => {
            this._dom['next'].remove();
            this._dom['prev'].remove();
        });
    }

    /** ----------------------------------------
         Hooks
     ---------------------------------------- */

    _hook() {
        this._hooks = {
            on: (type, callback) => {
                this._hooks[type] = !this._hooks[type] ? [] : this._hooks[type];
                this._hooks[type].push(callback);
            },
            beforeChange: () => {
                this._lock();
                if(this._hooks.before) this._hooks.before.map(hook => hook());
            },
            onChange: () => {
                if(this._hooks.change) this._hooks.change.map(hook => hook());
            },
            afterChange: () => {
                this._lock();
                if(this._hooks.after) this._hooks.after.map(hook => hook());
            },
            onEvent: e => {
                this._hooks.beforeChange();
                if(this._hooks.change) this._hooks.change.map(hook => hook(e));
            },
            onInit: () => {
                if(this._hooks.init) this._hooks.init.map(hook => hook());
            }
        };
    }

    /** ----------------------------------------
         Events
     ---------------------------------------- */

    _events() {
        const el = this._dom;
        el.next.addEventListener('click', e => this._nextSlide(e));
        el.prev.addEventListener('click', e => this._prevSlide(e));
    }

    _nextSlide(e) {
        if(!this._locked) return;
        this._data['event'] = 'next';
        this._hooks.onEvent(e);
        this._handleData('+');
    }

    _prevSlide(e) {
        if(!this._locked) return;
        this._data['event'] = 'prev';
        this._hooks.onEvent(e);
        this._handleData('-');
    }

    /** ----------------------------------------
         Watcher
     ---------------------------------------- */

    _watcher() {
        this._hooks.on('after', () => {
            this._index();
            this._updateCaption();
            this._updateCounter();
        });

        this._hooks.on('init', () => {
            this._index();
            this._updateCaption();
            this._updateCounter();
        });
    }

    /** ----------------------------------------
         Transitions
     ---------------------------------------- */

    _transitions() {
        const slides = this._slides;

        this._hooks.on('before', () => {
            const outSlide = slides[this._data.current - 1];
            const inSlide = slides[this._data[this._data.event] - 1];

            animate.fadeIn(inSlide.slide)
                .then(() => {
                    this._hooks.onChange();
                });

            animate.fadeOut(outSlide.slide)
                .then(() => {
                    this._hooks.afterChange();
                });
        });
    }

    /** ----------------------------------------
        Handle Position
     ---------------------------------------- */

    _handleData(operator) {
        const types = ['current', 'next', 'prev'];

        const handle = {
            '+': type => {
                this._data[type] += (this._data[type] < this._data.amount && 1 || -this._data.amount + 1)
            },
            '-': type => {
                this._data[type] -= (this._data[type] === 1 && 1 - this._data.amount || 1)
            }
        };

        types.map(handle[operator]);
    }

    /** ----------------------------------------
        Indexing
     ---------------------------------------- */

    _currentData(current = this._data.current) {
        this._data['current'] = current || 0;
    }

    _prevData(prev = this._data.prev) {
        this._data['prev'] = prev || this._data.current - 1;
    }

    _nextData(next = this._data.next) {
        this._data['next'] = next || this._data.current + 1;
    }

    /** ----------------------------------------
         Wrap Characters
     ---------------------------------------- */

    _wrapCharacters(el) {
        const characters = el.innerText.split('');
        el.innerText = '';

        characters.map(character => {
            el.insertAdjacentHTML('beforeend', `<span>${ character }</span>`);
        });
    }

    /** ----------------------------------------
        Set Index
     ---------------------------------------- */

    _index() {
        const counter = this._dom.el.querySelector('.' + this._el.classList[0] + '__count');
        if(!counter || !this._data.amount) return;

        counter.innerText = `${ this._data.current }/${ this._data.amount }`;
        this._wrapCharacters(counter);

        this._hooks.on('change', () => {
            animate.lettersOut(counter.childNodes);
        });

        this._hooks.on('after', () => {
            animate.lettersIn(counter.childNodes);
        });
    }

    /** ----------------------------------------
        Update Caption
     ---------------------------------------- */

    _updateCaption() {
        const caption = this._dom.caption;
        if(!caption || !this._data.amount) return;

        caption.innerText = this._slides[this._data.current - 1].caption;

        this._hooks.on('change', () => {
            animate.fadeOut(caption, 500);
        });

        this._hooks.on('after', () => {
            animate.fadeIn(caption, 500);
        });
    }

    /** ----------------------------------------
        Update Counter
     ---------------------------------------- */

     _updateCounter() {
        const counter = this._dom.counter;
        if(!counter || !this._data.amount) return;

        counter.innerText = `${this._slides[this._data.current - 1].index}/${this._slides.length}`;

        this._hooks.on('change', () => {
            animate.fadeOut(counter, 500);
        });

        this._hooks.on('after', () => {
            animate.fadeIn(counter, 500);
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        if(!this._el) return;

        this._dom['el'] = this._el;
        this._dom['holder'] = this._el.querySelector(this._holder);
        this._dom['caption'] = this._el.querySelector(this._caption);
        this._dom['counter'] = this._el.querySelector(this._counter);

        if(this._getSlides()) {
            this._getArrows();

            this._events();
            this._watcher();
            this._transitions();

            this._hooks.onInit();
        }
    }

}