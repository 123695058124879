/** ----------------------------------------
     Modules
 ---------------------------------------- */

import anime from "animejs";

/** ----------------------------------------
     Transition
 ---------------------------------------- */

const quoteLetterAnimation = target => {
    anime({
        targets: target,
        translateY: [75, 0],
        rotate: [-5, 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 550,
        loop: false,
        delay: (el, i) => 50 + 10 * i
    });
};

const quoteWordMobileAnimation = target => {
    anime({
        targets: target,
        translateY: [75, 0],
        rotate: [-5, 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 1500,
        loop: false,
        delay: 300
    });
};

/** ----------------------------------------
     Quotes
 ---------------------------------------- */

export class Quotes {

    constructor() {
        this.init();
    }

    /** ----------------------------------------
        Scroll
     ---------------------------------------- */

    _scroll() {
        const quotes = document.querySelectorAll('.quote-block');

        window.addEventListener('scroll', e => {
            [...quotes].map(item => {
                if(item.classList.contains('js-blocked')) return;
                const letters = item.querySelectorAll('.quote-block__letter');
                const wordMobile = item.querySelectorAll('.quote-block__word-mobile');

                if(this._inView(item) && !item.animated) {
                    item.animated = true;
                    quoteLetterAnimation(letters, 550);
                    quoteWordMobileAnimation(wordMobile, 1000);
                }
            });
        });
    }

    /** ----------------------------------------
        View
     ---------------------------------------- */

    _inView(el) {
        const bounding = el.getBoundingClientRect();
        return bounding.top - window.innerHeight < 0 && bounding.bottom > 0;
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        const quote = document.querySelector('.quote-block');

        if(!quote) return;
        this._scroll();
    }

}