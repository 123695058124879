import anime from 'animejs';

/** ----------------------------------------
    Label
 ---------------------------------------- */

export class Label {

    constructor(form) {
        this._form = form;
        this._els = [];
        this.init();
    }

    /** ----------------------------------------
         Retrieve Inputs
     ---------------------------------------- */

    _inputs() {
        const labels = this._form.querySelectorAll('.label__input');

        [...labels].map(label => {
           this._els.push({
               label: label,
               input: label.nextSibling.nextSibling
           });
        });
    }

    /** ----------------------------------------
         Set Events
     ---------------------------------------- */

    _events() {
        this._els.map(el => {
            el.input.addEventListener('focus', () => {
                this._active(el.label);
            });

            el.input.addEventListener('focusout', () => {
                el.input.value.length === 0 && this._inActive(el.label)
            });
        });
    }

    /** ----------------------------------------
         Modify DOM State
     ---------------------------------------- */

    _active(el) {
        el.classList.add('is-active');
    }

    _inActive(el) {
        el.classList.remove('is-active');
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        if(!this._form) return;
        this._inputs();
        this._events();
    }

}