import axios from 'axios';
import UrlQuery from '../helpers/UrlQuery';

export class ArticleFeed {

    constructor() {
        this.resultsEl = document.querySelector('.js-articles');

        if (this.resultsEl === null)
            return;

        this.extraArticlesGrid = document.getElementById('loaded-articles-grid');
        this.observableElements = document.querySelector('#load-new-articles');
        this.checkboxInputElems = document.querySelectorAll('.js-filter input[type="checkbox"]');
        this.selectElems = document.querySelectorAll('.js-filter select');
        this.urlQuery = new UrlQuery();
        this.finished = this.resultsEl.dataset.finished;
        this.fetchingData = false;
        this.endpoint = '/feeds/articles';

        this.init();
    }

    setSelectByQuery() {
        for (const entry of this.urlQuery.url.searchParams.entries()) {
            this.selectElems.forEach(selectEl => {
                if (entry[0] === selectEl.name) {
                    selectEl.value = entry[1];
                    this.updateSelectFilters(entry[1]);
                }
            });
        }
    }

    setCheckboxInputByQuery() {
        this.checkboxInputElems.forEach(inputEl => {
            inputEl.checked = false;
        });

        for (const entry of this.urlQuery.url.searchParams.entries()) {
            this.checkboxInputElems.forEach(inputEl => {
                if ((entry[0] === inputEl.name || entry[0] === `${inputEl.name}[]`) && entry[1] === inputEl.value)
                    inputEl.checked = true;
            });
        }
    }

    addSelectListeners() {
        this.selectElems.forEach(selectEl => {
            selectEl.addEventListener('change', () => {
                this.urlQuery.delete('page');
                this.setCheckboxInputByQuery();
                this.updateSelectQuery(selectEl.name, selectEl.value);
                this.updateSelectFilters(selectEl.value);
                this.filter();
            });
        });
    }

    addCheckboxListeners() {
        this.checkboxInputElems.forEach(inputEl => {
            inputEl.addEventListener('change', () => {
                this.urlQuery.delete('page');
                this.updateCheckboxQuery(inputEl.name, inputEl.value, inputEl.checked);
                this.filter();
            });
        });
    }

    updateSelectQuery(name, value) {
        value = value === 'all' ? '' : value;
        
        value
            ? this.urlQuery.set(name, value)
            : this.urlQuery.delete(name, value);

        let types = [];

        this.checkboxInputElems.forEach(inputEl => {
            if (!types.includes(inputEl.name))
                types.push(inputEl.name);
        });

        types.forEach(type => {
            this.urlQuery.delete(type);
        });
    }

    updateSelectFilters(value) {
        this.checkboxInputElems.forEach(inputEl => {
            inputEl.parentElement.classList.contains(`js-filter-${value}`)
                ? inputEl.parentElement.classList.add('is-visible')
                : inputEl.parentElement.classList.remove('is-visible');
        });
    }

    updateCheckboxQuery(name, value, checked) {
        checked
            ? this.urlQuery.append(name, value)
            : this.urlQuery.delete(name, value);
    }

    fetchResults(addPrevious = false) {
        this.fetchingData = true;

        if (addPrevious)
            this.urlQuery.set('addPreviousPages', true, false);

        return axios.get(this.endpoint + this.urlQuery.search).then(res => {
            this.urlQuery.delete('addPreviousPages', false);
            this.fetchingData = false;
            return res.data;
        });
    }

    loadNextPage() {
        let page = parseInt(this.urlQuery.has('page') ? this.urlQuery.get('page') : 1, 10) + 1;
        this.urlQuery.set('page', page);

        this.filter(true);
    }

    filter(append = false) {
        this.fetchResults().then(response => {
            this.finished = response.finished;
            this.updateDom(response.dom, append);
        }).finally(() => {
            this.fetchingData = false;
        });
    }

    updateDom(data, append = false) {
        if (append) {
            let lastGridElement = this.extraArticlesGrid.lastElementChild;

            if (lastGridElement === null) {
                this.extraArticlesGrid.innerHTML = data;
            } else {
                lastGridElement.insertAdjacentHTML('afterend', data);
            }
        } else {
            this.resultsEl.innerHTML = data;
            this.extraArticlesGrid = document.getElementById('loaded-articles-grid');
        }
    }

    addLoadMoreObserver() {
        this.observer = new IntersectionObserver(entries => {
            if (this.fetchingData || this.finished) return;

            if (entries[0].isIntersecting)
                this.loadNextPage();
        }, { threshold: [0] });
        this.observer.observe(this.observableElements);
    }

    init() {
        this.setSelectByQuery();
        this.setCheckboxInputByQuery();
        this.addSelectListeners();
        this.addCheckboxListeners();
        this.addLoadMoreObserver();
    }
}
