/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Elements
---------------------------------------- */

const buttons = document.querySelectorAll('.buttons__item');

/* ----------------------------------------
    Education
---------------------------------------- */

let education = anime.timeline({
    easing: 'easeInOutCirc',
    autoplay: false,
    loop: false
});

if(buttons.length) {
    education
        .add({
            targets: ['#blue-1'],
            scale: 1.1,
            translateY: -10,
            translateX: 10,
            rotate: -90,
            duration: 350,
        })
        .add({
            targets: ['#aqua-1'],
            scale: 0.9,
            translateY: 10,
            translateX: -100,
            rotate: 90,
            duration: 350,
        }, 0)
        .add({
            targets: ['#blue-1'],
            translateY: 0,
            translateX: 0,
            rotate: 0,
            delay: 500,
            duration: 350,
        }, 350)
        .add({
            targets: ['#aqua-1'],
            translateY: 0,
            translateX: -10,
            rotate: 0,
            delay: 500,
            duration: 350,
        }, 350)
        .add({
            targets: ['#blue-1'],
            scale: 0.9,
            translateY: -30,
            translateX: -10,
            rotate: 0,
            delay: 500,
            duration: 350,
        }, 350)
        .add({
            targets: ['#aqua-1'],
            scale: 1.1,
            translateX: 5,
            translateY: 30,
            rotate: 0,
            delay: 500,
            duration: 350,
        }, 350);

    education['leave'] = () => {
        anime({
            targets: ['#aqua-1', '#blue-1'],
            scale: 1,
            translateY: 0,
            translateX: 0,
            rotate: 0,
            duration: 1000
        });
    };
}

/* ----------------------------------------
    Collections
---------------------------------------- */

let collections = anime.timeline({
    autoplay: false,
    loop: false
});

if(buttons.length) {
    collections
        .add({
            targets: ['#top'],
            translateY: -15,
            translateX: -10,
            scale: 1.15,
            duration: 750,
        })
        .add({
            targets: ['#middle'],
            translateY: -7.5,
            translateX: -10,
            scale: 1.15,
            duration: 750,
        }, 0)
        .add({
            targets: ['#bottom'],
            translateY: 5,
            translateX: -10,
            scale: 1.15,
            duration: 750,
        }, 0);

    collections['leave'] = () => {
        anime({
            targets: ['#top', '#middle', '#bottom'],
            translateY: 0,
            translateX: 0,
            scale: 1,
            duration: 1000
        });
    };
}

/* ----------------------------------------
    Article
---------------------------------------- */

let article = anime.timeline({
    autoplay: false,
    loop: false
});

if(buttons.length) {
    article
        .add({
            targets: ['#icon'],
            translateX: -2,
            scale: [0.95, 1.05],
            duration: 325,
            easing: 'easeOutQuint'
        })
        .add({
            targets: ['#page-right-1', '#page-right-2'],
            offset: [100, 0],
            duration: 325,
            easing: 'easeOutQuint'
        }, 50)
        .add({
            targets: ['#page-left-1', '#page-left-2'],
            offset: [100, 0],
            duration: 325,
            easing: 'easeOutQuint'
        }, 175)
        .add({
            targets: ['#ball-left-1', '#ball-left-2'],
            offset: [100, 0],
            duration: 250,
            easing: 'easeOutQuint'
        }, 175);

    article['leave'] = () => {
        anime({
            targets: ['#icon', '#page-left-1', '#page-left-2', '#page-right-1', '#page-right-2', '#ball-left-1', '#ball-left-2'],
            translateX: 0,
            scale: 0.95,
            offset: [0, 100],
            duration: 350,
            easing: 'easeOutCirc'
        });
    };
}

/* ----------------------------------------
    Services
---------------------------------------- */

let services = anime.timeline({
    autoplay: false,
    loop: false
});

if(buttons.length) {
    services
        .add({
            targets: ['#rounds'],
            rotate: [0, 70],
            duration: 1250,
        })
        .add({
            targets: ['#rounds'],
            scale: [1, 0.9],
            rotate: [70, 170],
            duration: 1000,
        });

    services['leave'] = () => {
        anime({
            targets: ['#rounds'],
            scale: 1,
            rotate: 0,
            duration: 1250,
            begin: () => {
                services.restart();
                services.pause();
            }
        });
    };
}

/** ----------------------------------------
    Exports
 ---------------------------------------- */

export {
    article,
    collections,
    education,
    services
};