/** ----------------------------------------
    Url Query
 ---------------------------------------- */

export default class UrlQuery {
        
    constructor() {
        this.url = new URL(window.location);
    }

    /**
     * Get search string
     * @returns {string} search
     */
    get search() {
        let search = '';

        for (const entry of this.url.searchParams.entries()) {
            search+= `${(search ? '&' : '?')}${entry[0]}=${entry[1]}`;
        }

        return search;
    }

    /**
     * Get param by key
     * @param {string} key
     * @returns {string|array} value
     */
    get(key) {
        return this.url.searchParams.get(key);
    }

    /**
     * Set param by key with value
     * @param {string} key
     * @param {string|Object|null} value
     * @returns {void}
     */
    set(key, value, pushState = true) {
        if (typeof value === 'object') {
            this.delete(key, null, false);
            Array.from(value).forEach(val => this.append(key, val, false));
        } else {
            (value)
                ? this.url.searchParams.set(key, value)
                : this.delete(key, null, false);
        }

        if (pushState)
            this.pushState();
    }

    /**
     * Set all params with
     * @param {Object} obj
     * @returns {void}
     */
    setAll(obj, pushState = true) {
        Object.keys(obj).forEach(key => {
            this.set(key, obj[key], false);
        });

        if (pushState)
            this.pushState();
    }

    /**
     * Append param by key with value
     * @param {string} key
     * @param {string|null} value
     * @returns {void}
     */
    append(key, value, pushState = true) {
        this.url.searchParams.append(`${key}[]`, value);

        if (pushState)
            this.pushState();
    }

    /**
     * Has param by key. If value given check if value exists in array.
     * @param {string} key
     * @param {string|null} value
     * @returns {bool} value
     */
    has(key, value) {
        return (value)
            ? this.url.searchParams.getAll(key).filter(val => val === value) > 0
            : this.url.searchParams.has(key);
    }

    /**
     * Delete param by key. If value given remove from array.
     * @param {string} key
     * @param {string|null} value
     * @param {bool} pushState
     * @returns {void}
     */
    delete(key, value, pushState = true) {
        if (value) {
            let values = [
                ...this.url.searchParams.getAll(key),
                ...this.url.searchParams.getAll(`${key}[]`),
            ].filter(val => val !== value);

            this.delete(key, null, false);
    
            for (let i = 0; i < values.length; i++) {
                this.append(key, values[i], false);
            }
        } else if (key) {
            this.url.searchParams.delete(key);
            this.url.searchParams.delete(`${key}[]`);
        } else {
            this.url.searchParams.delete();
        }

        if (pushState)
            this.pushState();
    }

    
    /**
     * Clear params
     * @param {bool} pushState
     * @returns {void}
     */
    clear(pushState = true) {
        let keys = [];

        for (const key of this.url.searchParams.keys()) {
            keys.push(key);
        }

        keys.reverse().forEach(key => this.delete(key, null, false));

        if (pushState)
            this.pushState();
    }

    /**
     * Push state
     * @returns {void}
     */
    pushState() {
        this.url.searchParams.sort();
        history.pushState({}, null, this.url.origin + this.url.pathname + this.search);
    }
}
