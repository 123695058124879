/* ----------------------------------------
    Node Modules
---------------------------------------- */

import axios from 'axios';
import { Form } from '@js/module/Form';

/* ----------------------------------------
    Recaptcha Form
---------------------------------------- */

/**
 * This class will handle all functionality
 * for the recaptcha form.
 */

export class RecaptchaForm extends Form {

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    constructor(config) {
        super(config);

        this.action = config.action;
        this.key = this.form.dataset.key;
    }

    /** ----------------------------------------
        Initialize
     ---------------------------------------- */

    init() {
        if(!this.action || !this.key)
            return;

        grecaptcha.ready(() => {
            this.form.addEventListener('submit', (e) => {
                e.preventDefault();
                this._processingSubmit();

                grecaptcha.execute(this.key, { action: this.action }).then(token => {
                    const values = this._getFieldValues()
                    const options = { _recaptcha: token, ...values };

                    this._callEndoint(options);
                });
            });
        });
    }
}