/* ----------------------------------------
    Node Modules
---------------------------------------- */

import axios from 'axios';

/* ----------------------------------------
    Recaptcha Form
---------------------------------------- */

/**
 * This class will handle all functionality
 * for the recaptcha form.
 */

export class Form {

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    constructor(config) {
        this.form = config.form;

        if(!this.form)
            return;

        this.url = this.form.action;
        this.key = this.form.dataset.key;
        this.fields = this.form.querySelectorAll('input, textarea, select');
        this.buttons = this.form.querySelectorAll('input[type="submit"], button');
        this.errorClass = 'form__error';
    }

    /** ----------------------------------------
        Callback setters
     ---------------------------------------- */

    set success(fn) {
        this.successCallback = fn;
    }

    set error(fn) {
        this.errorCallback = fn;
    }

    /** ----------------------------------------
        Highlight/unhighlight errors
     ---------------------------------------- */

    _highlightErrors() {
        Object.keys(this.errorData.errors).forEach(key => {
            const skip = [];
            [...this.fields].forEach(fieldNode => {
                if (fieldNode.name === key && !skip.includes(key)) {
                    if (fieldNode.type === 'radio') {
                        skip.push(key);
                        this._addErrorMessage(fieldNode, this.errorData.errors[key]);
                    } else {
                        fieldNode.classList.add('required');
                        this._addErrorMessage(fieldNode, this.errorData.errors[key]);
                    }
                }
            });
        });
    }

    _unhighlightErrors() {
        [...this.fields].forEach(fieldNode => {
            fieldNode.classList.remove('required');
            this._deleteErrorMessage(fieldNode);
        });
    }

    /** ----------------------------------------
        Add/remove error messages
     ---------------------------------------- */

    _addErrorMessage(fieldNode, message) {
        const errorNode = document.createElement('div');
        errorNode.innerHTML = message;
        errorNode.className = this.errorClass;
        fieldNode.errorNode = errorNode;

        if (fieldNode.type === 'radio') {
            fieldNode.parentNode.parentNode.appendChild(errorNode);
        } else if (fieldNode.type === 'checkbox') {
            fieldNode.parentNode.insertBefore(errorNode, fieldNode.nextElementSibling.nextSibling);
        } else {
            fieldNode.parentNode.insertBefore(errorNode, fieldNode.nextSibling);
        }
    }

    _deleteErrorMessage(fieldNode) {
        if (!fieldNode.errorNode) return;

        fieldNode.errorNode.parentNode.removeChild(fieldNode.errorNode);
        delete fieldNode.errorNode;
    }

    /** ----------------------------------------
        Set fields disabled state
     ---------------------------------------- */

    _setFieldsDisabledState(state) {
        [...this.fields].forEach(fieldNode => {
            fieldNode.disabled = state;
        });
    }

    /** ----------------------------------------
        Set buttons disabled state
     ---------------------------------------- */

    _setButtonDisabledState(state) {
        [...this.buttons].forEach(buttonNode => {
            if (!buttonNode.originalText) buttonNode.originalText = buttonNode.innerHTML;

            buttonNode.disabled = state;
            buttonNode.innerHTML = (state) ? 'Versturen...' : buttonNode.originalText;
        });
    }

    /** ----------------------------------------
        Get field values
     ---------------------------------------- */

    _getFieldValues() {
        const values = {};
        
        [...this.fields].forEach(fieldNode => {
            if (['radio', 'checkbox'].includes(fieldNode.type)) {
                if (!values[fieldNode.name])
                    values[fieldNode.name] = null;
                
                if (fieldNode.checked) 
                    values[fieldNode.name] = fieldNode.value;
            } else {
                values[fieldNode.name] = fieldNode.value;
            }
        });

        return values;
    }

    /** ----------------------------------------
        processing submit
     ---------------------------------------- */

    _processingSubmit(response) {
        this._unhighlightErrors();
        this._setFieldsDisabledState(true);
        this._setButtonDisabledState(true);
    }

    /** ----------------------------------------
        call endpoint
     ---------------------------------------- */

    _callEndoint(options) {
        axios.post(this.url, options)
            .then(response => {
                this._handleSuccess(response);
            })
            .catch(error => {
                this._handleError(error);
            });
    }

    /** ----------------------------------------
        handle responses
     ---------------------------------------- */

    _handleSuccess(response) {
        if(!response.data) return;

        this.successData = response.data;
        this._setFieldsDisabledState(false);
        this._setButtonDisabledState(false);

        if (this.successCallback)
            this.successCallback(this.successData);
    }

    _handleError(error) {
        if (!error.response) return;

        this.errorData = error.response.data;

        this._setFieldsDisabledState(false);
        this._setButtonDisabledState(false);
        this._highlightErrors();

        if (this.errorCallback)
            this.errorCallback(this.errorData);
    }

    /** ----------------------------------------
        Initialize
     ---------------------------------------- */

    init() {
        if(!this.form)
            return;

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this._processingSubmit();
            const values = this._getFieldValues()
            const options = { ...values };

            this._callEndoint(options);
        });
    }
}