/** ----------------------------------------
    Slider Filter
 ---------------------------------------- */

import { Swiper } from 'swiper/bundle';

const swiperSlider = document.querySelectorAll('.swiper-container');

swiperSlider.forEach((slider, index) => {
    if(index === 0) {
        slider.classList.add('active-slider');
    }
});

export const swiperOptions = {
    slidesPerView: 1,
    spaceBetween: 15,
    observer: true,
    observeParents: true,

    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        1280: {
            slidesPerView: 3,
            spaceBetween: 30
        }
    },

    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    navigation: {
        nextEl: '.service-slider__next',
        prevEl: '.service-slider__prev',
    },
};

const swiper = new Swiper('.active-slider', swiperOptions, {});
 
swiper.init;

export class SliderFilter {

    constructor(el) {
        this._el = el;
        this._radioElems = this._el.querySelectorAll('input[type="radio"]');
        this._filters = Array.from(this._radioElems).map(radioEl => {
            return {
                radioEl: radioEl,
                sliderEl: document.querySelector(`[data-slider="${radioEl.value}"]`),
            };
        });

        this.init();
    }

    showFilter(ev) {
        ev.preventDefault();
        
        this._filters.forEach(filter => {
            if (filter.radioEl.value === ev.target.value) {
                filter.sliderEl.classList.remove('is-hidden');
                swiper.destroy(true, true);
                document.querySelector('.active-slider').classList.remove('active-slider');
                filter.sliderEl.querySelector('.swiper-container').classList.add('active-slider');
                new Swiper('.active-slider', swiperOptions, {
                });
            } else {
                filter.sliderEl.classList.add('is-hidden');
            }
        });
    }

    init() {
        this._filters.forEach(filter => {
            filter.radioEl.addEventListener('change', this.showFilter.bind(this));
        });
    }
}