/** ----------------------------------------
     Thanks Template
 ---------------------------------------- */

const thanks = (title = 'Bedankt', message,) => {
    return `
        <div class="popup__title">${ title }</div> 
        <p class="popup__info">${ message }</p>
        <div class="popup__actions"> 
            <span class="btn-ghost--blue" id="js-close">Close</span>
        </div>
    `;
};

/** ----------------------------------------
    Exports
 ---------------------------------------- */

export {
    thanks
}