/** ----------------------------------------
    Video
 ---------------------------------------- */

/**
 * This class will initiate the youtube selector
 */

 export class Video {

    constructor(el) {
        this.el = el;
        this.thumbEl = this.el.querySelector('.video__thumb');
        this.imgEl = this.el.querySelector('.video__img');
        this.iframeEl = this.el.querySelector('.video__iframe');

        if (!this.iframeEl) {
            this.el.remove();
            return;
        }

        this.image = this.iframeEl.dataset.img ? this.iframeEl.dataset.img : null;
        this.provider = this.iframeEl.dataset.provider ? this.iframeEl.dataset.provider : null;
        this.iframe = this.iframeEl.contentWindow;
        this.url = this.el.dataset.videoUrl;

        // Dont init video if no url is set
        if (!this.url) return;

        // init
        this.init();
    }

    setThumbImage() {
        this.imgEl.src = this.image;
    }

    setEvents() {
        this.thumbEl.addEventListener('click', this.play.bind(this));
        this.el.addEventListener('pause', this.pause.bind(this));
        this.el.addEventListener('play', this.play.bind(this));
    }

    play(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.thumbEl.style.display = 'none';

        if (this.provider.includes('YouTube'))
            this.iframe.postMessage('{"event":"command","func":"playVideo","args":""}','*');
        
        if (this.provider.includes('Vimeo'))
            this.iframe.postMessage('{"method":"play"}','*');
    }

    pause() {
        if (this.provider.includes('YouTube'))
            this.iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}','*');
        
        if (this.provider.includes('Vimeo'))
            this.iframe.postMessage('{"method":"pause"}','*');

        if (this.provider.includes('YouTube') || this.provider.includes('Vimeo'))
            this.thumbEl.style.display = 'block';
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.setThumbImage();

        if (this.provider.includes('YouTube') || this.provider.includes('Vimeo')) {
            this.setEvents();
        } else {
            this.thumbEl.style.display = 'none';
        }
    }
}