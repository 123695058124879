/** ----------------------------------------
     Component
 ---------------------------------------- */

import { Toast } from '@js/component/Toast.js';

/** ----------------------------------------
    Social Share
 ---------------------------------------- */

/**
 * Use this to share a page on social media,
 * with custom text / urls. You can also copy
 * text to clipboard, see below 'copyToClipBoard'
 */

export default class SocialShare extends Toast{

    /**
     * @element         element that inherits interaction
     * @default         default configuration
     * @trigger         element that triggers interaction
     * @url             current page url
     * @type            default social type
     * @init            calls the init method
     */

    constructor(element) {
        super();
        if(!element) return;
        this.elem = element;
        this.config = JSON.parse(element.dataset.config);
        this.trigger = element;
        this.url = window.location.href;
        this.toast = new Toast();
        this.type = 'facebook';
        this.init();
    }

    /** ----------------------------------------
        Add Event
     ---------------------------------------- */

    addEvents() {
        this.trigger.onclick = (e) => {
            e.preventDefault();
            this.shareElement(e);
        };
    }

    /** ----------------------------------------
        Share Type
     ---------------------------------------- */

    shareElement() {
        let shareURL = '';

        if(this.type === 'copy') {
            if(this.trigger.classList.contains('is-active')) return;
            this.trigger.classList.add('is-active');
            this.copyToClipBoard();
            return false;
        }

        if(this.type === 'twitter') {
            shareURL = 'http://twitter.com/share?url=';
        }
        if(this.type === 'facebook') {
            shareURL = 'https://www.facebook.com/sharer/sharer.php?u=';
        }
        if(this.type === 'google-plus') {
            shareURL = 'https://plus.google.com/share?url=';
        }
        if(this.type === 'whatsapp') {
            shareURL = 'https://api.whatsapp.com/send?text=';
        }
        if(this.type === 'linkedin') {
            shareURL = 'https://www.linkedin.com/shareArticle?mini=true&url=';
        }

        window.open(
            shareURL + this.url,
            this.type.charAt(0).toUpperCase(),
            'height=450, width=550, top='+(window.innerHeight/2 - 225) +', left='+window.innerWidth/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
        );
    }

    /** ----------------------------------------
        Copy
     ---------------------------------------- */

    copyToClipBoard() {
        const url = window.location.href;
        const input = document.createElement('input');

        input.style.position = 'absolute';
        input.style.top = '-9999px';
        input.style.left = '-9999px';
        input.value = url;

        document.body.appendChild(input);

        input.select();
        input.setSelectionRange(0, 99999);

        document.execCommand('copy');

        input.remove();

        this.toast.setToast('De link is gekopieerd');
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.type = (this.config.type !== undefined) ? this.config.type : this.type;
        this.url = (this.config.customurl !== undefined) ? this.config.customurl : this.url;
        this.addEvents();
    }
}
