import anime from 'animejs';

/** ----------------------------------------
    Toast
 ---------------------------------------- */

/**
 * The toast sets a notification to the DOM
 * to notify the user about a certain event
 * or action that has been triggered.
 */

export class Toast {

    /** ----------------------------------------
        Template
     ---------------------------------------- */

    toastTemplate(message, button) {
        button = button ? `<a class="${ button.class }" href="${ button.href }">${ button.name }</a>` : '';

        const html = `
            <div class="toast">
                <div class="toast__holder">
                    <span class="toast__message">${ message }</div> ${ button }
                </div>
            </div> 
        `;

        document.body.insertAdjacentHTML('beforeend', html);

        this.animateToast();
    }

    /** ----------------------------------------
        Animate
     ---------------------------------------- */

    animateToast() {
        const toast = document.querySelector('.toast');

        const open = anime({
            targets: toast,
            translateY: ['100%', 0],
            delay: 250,
            duration: 500,
            easing: 'easeOutQuad'
        });

        open.finished.then(() => {
            setTimeout(() => {
                let close = anime({
                    targets: toast,
                    translateY: '100%',
                    duration: 500,
                    easing: 'easeOutQuad'
                });

                close.finished.then(() => {
                    toast.remove();
                });
            }, 2000);
        });
    }

    /** ----------------------------------------
        Set Toast
     ---------------------------------------- */

    setToast(message, button) {
        this.toastTemplate(message, button);
    }

}