/** ----------------------------------------
    Scroll To
 ---------------------------------------- */

/**
 * This class will animate a scroll towards
 * an given anchor point on the page.
 */

export class ScrollTo {

    /**
     * @init            init class
     * @duration        animation duration
     * @destination     the target destination
     */

    constructor(element, duration) {
        this.clickEvent(element);
        this.duration = duration;
        this.destination = '';
    }

    /** ----------------------------------------
        Click Event
     ---------------------------------------- */

    /**
     * Looks for a click element within a given
     * trigger and will initiate the scrollTo
     * method and animate the scroll towards the
     * given target.
     */

    clickEvent(element) {
        let _this = this;

        element.addEventListener('click', () => {
            let anchor = '#' + element.dataset.scrollTo;
            _this.scrollTo(anchor);
        });
    }

    /** ----------------------------------------
        Set Scroll Animation
     ---------------------------------------- */

    scrollTo(anchor) {
        let _this = this;

        let destination = document.querySelector(anchor);

        let easings = {
            easeInOutCubic(t) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            }
        };

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

        const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - 200;

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / _this.duration));
            const timeFunction = easings['easeInOutCubic'](time);
            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }
}