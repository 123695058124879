/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Randomize
---------------------------------------- */

const move = () => {
    return anime.random(-5, 2) + 'rem';
};

const speed = () => {
    return anime.random(2500, 4000);
};

/* ----------------------------------------
    Animations
---------------------------------------- */

const loop = el => anime({
    targets: el,
    translateX: ['10%', 0],
    translateY: [0, '10%'],
    duration: 1250,
    loop: true
});

const random = (...els) => anime({
    targets: [...els],
    translateX: [
        { value: 0  },
        { value: move  },
        { value: 25 },
        { value: move },
        { value: 0 }
    ],
    translateY: [
        { value: 0 },
        { value: move },
        { value: 50 },
        { value: move },
        { value: 0 }
    ],
    duration: speed,
    loop: true
});

/* ----------------------------------------
    404 Error
---------------------------------------- */

export class Error {

    constructor() {
        this._init();
    }

    _animate(el) {
        const stacked = el.querySelector('#stacked');
        loop(stacked);

        const circleOne = el.querySelector('#circle-1');
        const circleTwo = el.querySelector('#circle-2');
        random(circleOne, circleTwo);
    }

    _init() {
        const error = document.querySelector('.error-404');
        if(!error) return;

        this._animate(error);
    }

}
