/** ----------------------------------------
    Tabs
 ---------------------------------------- */
import { Swiper } from 'swiper/bundle';
import { swiperOptions } from '@js/module/SliderFilter';

export class Tabs {

    constructor(el) {
        this.el = el;
        // this.mainEl = document.getElementByName('body');
        this.mainEl = document.getElementById('main');
        this.targetElems = this.el.querySelectorAll('[data-tab-target]');
        this.tabs = Array.from(this.targetElems).map(tabEl => {
            return {
                tabEl: tabEl,
                targetEl: document.querySelector(`[data-tab="${tabEl.dataset.tabTarget}"]`),
            };
        });

        this.init();
    }

    tabClick(ev) {
        ev.preventDefault();
        
        this.tabs.forEach(tab => {
            if (tab.tabEl.dataset.tabTarget === ev.target.dataset.tabTarget) {
                this.showTab(tab);
            }
        });
    }

    showTab(selectedTab) {
        this.tabs.forEach(tab => {
            if (tab === selectedTab) {
                tab.targetEl.classList.remove('is-hidden');
                tab.tabEl.classList.add('is-active');

                this.changeTheme(tab, this.mainEl);
                this.changeTheme(tab, this.el);
                // this.changeTheme(tab, document.body);

                this.activateSliders(tab);
            } else {
                tab.targetEl.classList.add('is-hidden');
                tab.tabEl.classList.remove('is-active');
            }
        });
    }

    changeTheme(tab, el) {
        if (!el)
            return;
        
        tab.targetEl.classList.contains('theme-dark')
            ? el.classList.add('theme-dark')
            : el.classList.remove('theme-dark');
    }

    activateSliders(tab) {
        document.querySelector('.active-slider').classList.remove('active-slider');
                
        tab.targetEl.querySelector('.swiper-container').classList.add('active-slider');

        new Swiper('.active-slider', swiperOptions, {});
    }

    init() {
        this.tabs.forEach(tab => {
            tab.tabEl.addEventListener('click', this.tabClick.bind(this));
        });

        this.showTab(this.tabs[0]);
    }
}

