import throttle from 'lodash/throttle';

/** ----------------------------------------
    Sticky Navigation
 ---------------------------------------- */

/**
 * This class will set a sticky navigation
 * bar once the user scrolls upwards.
 */

export class StickyNavigation {

    /**
     * @nav             navigation bar
     * @prevScroll      previous scroll position
     * @scroll          current scroll position
     * @ticking         scrolling state
     * @init            init class
     */

    constructor() {
        this.nav = document.querySelector('nav');
        this.cta = document.querySelector('.nav-cta');
        this.prevScroll = 0;
        this.scroll = 0;
        this.ticking = false;
        this.init();
    }

    /** ----------------------------------------
        Register Scroll
     ---------------------------------------- */

    /**
     * Register the current scroll point
     */

    registerScroll() {
        this.scroll = window.scrollY;
        this.requestTick();
    }

    /** ----------------------------------------
        Request Ticking
     ---------------------------------------- */

    /**
     * Check the animation frame and set ticking
     * to see if state should be updated.
     */

    requestTick() {
        if(this.nav && this.nav.classList.contains('is-active'))
            return;

        if(!this.ticking)
            window.requestAnimationFrame(this.updateState.bind(this));

        this.ticking = true;
    }

    /** ----------------------------------------
        Set Navigation
     ---------------------------------------- */

    /**
     * Looks at scroll direction to see if
     * navigation should be visible or not.
     */

    updateState() {
        const cookiebanner = document.querySelector('.cc-banner');

        if (this.scroll >= this.prevScroll && this.scroll > 300 && this.scroll !== this.prevScroll && ((this.scroll - this.prevScroll) > -100)) {
            this.nav && this.nav.classList.add('is-hidden');
            this.nav && this.nav.classList.remove('is-visible');
            if (cookiebanner && this.cta) {
                cookiebanner.classList.add('with-nav');
            }

            if(this.cta && this.scroll > 800 && window.innerWidth > 640) {
                this.cta.classList.add('is-visible');
                this.cta.classList.remove('is-hidden');

                if (cookiebanner) {
                    cookiebanner.classList.add('with-nav');
                }
            }

            this.prevScroll = this.scroll;
        } else {
            if (this.scroll !== this.prevScroll) {
                this.nav && this.nav.classList.remove('is-hidden');
                this.nav && this.nav.classList.add('is-visible');

                if(this.cta && this.scroll < 800 && window.innerWidth > 640) {
                    this.cta.classList.remove('is-visible');
                    this.cta.classList.add('is-hidden');

                    if (cookiebanner) {
                        cookiebanner.classList.remove('with-nav');
                    }
                }

                this.prevScroll = this.scroll;
            }
        }

        if(this.scroll >= this.prevScroll && this.scroll > 50) {
            this.nav && this.nav.classList.add('has-scrolled');
        } else {
            if (this.scroll < 50) {
                this.nav && this.nav.classList.remove('has-scrolled');
            }
        }

        this.ticking = false;
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        window.addEventListener('scroll', throttle(
            this.registerScroll.bind(this), 250)
        );
    }
}