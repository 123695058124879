import anime from 'animejs';

/** ----------------------------------------
     Easter Egg Confetti
 ---------------------------------------- */

export class Confetti {

    /**
     * @init            calls the init method
     */

    constructor() {
        this.logo = document.querySelector('.footer__vruchtvlees');
        if (!this.logo) return;
        
        this.coordinates = {};
        this.count = 1;
        this.hover = false;
        this.easter = false;
        this.interval = null;
        this.init();
    }

    /** ----------------------------------------
        Check Hover Event
     ---------------------------------------- */

    mouseEnter() {
        this.logo.addEventListener('mouseover', e => {
            if(!this.easter) this.startInterval(e);
        });
    }

    mouseLeave() {
        this.logo.addEventListener('mouseout', e => {
            this.endInterval();
        });
    }

    /** ----------------------------------------
        Counter
     ---------------------------------------- */

    startInterval(e){
        let _this = this;

        this.interval = setInterval(() => {
            _this.countInterval(e);
        }, 1000);
    }

    countInterval(e){
        let _this = this;
        this.count++;

        if(_this.count === 3) {
            this.coordinates.y = e.pageY + 10;
            this.coordinates.x = e.pageX + 10;
            this.setLoader();
        }

        if(_this.count === 10) {
            _this.endInterval();
            _this.setEmojis();
        }
    }

    endInterval(){
        let _this = this;
        this.count = 1;

        this.removeLoader();
        clearInterval(_this.interval);
    }

    /** ----------------------------------------
        Loader
     ---------------------------------------- */

    makeString() {
        let text = "";
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

        for (let i = 0; i < 5; i++)
            text += characters.charAt(Math.floor(Math.random() * characters.length));

        return text;
    }

    setLoader() {
        let top = this.coordinates.y;
        let left = this.coordinates.x;

        let html = `
            <div class="easter" style="position: absolute; top: ${ top }px; left: ${ left }px">
                <div class="easter__loader">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" fill="white">
                        <path opacity="0" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"/>
                        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
                            <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
            </div>
        `;

        document.body.insertAdjacentHTML('beforeend', html);

        let easter = document.querySelector('.easter');
        easter.classList.add('is-active');
    }

    removeLoader() {
        let loader = document.querySelector('.easter__loader');

        if(!loader)
            return;

        let remove = anime({
            targets: loader,
            opacity: 0,
            duration: 350,
            easing: 'easeInOutQuad'
        });

        remove.finished.then(function() {
            loader.remove();
        });
    }

    setEmojis() {
        let _this = this;
        this.easter = true;

        let juice = [
            ['kiwi', '🥝'],
            ['tomato', '🍅'],
            ['pineapple', '🍍'],
            ['green-apple', '🍏'],
            ['red-apple', '🍎'],
            ['pear', '🍐'],
            ['orange', '🍊' ],
            ['lemon', '🍋'],
            ['banana', '🍌'],
            ['melon', '🍉'],
            ['grape', '🍇'],
            ['strawberry', '🍓'],
            ['cherry', '🍒'],
            ['peach', '🍑']
        ];

        let confetti = setInterval(function() {
            let id = _this.makeString();
            let random = Math.floor(Math.random() * juice.length);

            let emoji = `
                <div class="easter__fruits" id="${ id }">${ juice[random][1] }</div>
            `

            let loader = document.querySelector('.easter');
            loader.insertAdjacentHTML('afterbegin', emoji);

            _this.animateEmojis(id);
        }, 100);

        setTimeout(() => {
            clearInterval(confetti);
        }, 3500);
    }

    animateEmojis(id) {
        let emoji = document.getElementById(id);

        let confetti = anime({
            targets: emoji,
            top: [
                {
                    value: function() {
                        return anime.random(-25, 25);
                    },
                    duration: 0
                }
            ],
            left: [
                {
                    value: function() {
                        return anime.random(-25, 25);
                    },
                    duration: 0
                }
            ],
            rotate: function() {
                return anime.random(-35, 35);
            },
            translateX: function() {
                return anime.random(-150, 150);
            },
            translateY: [{
                value: function() {
                    return anime.random(-150, -400);
                }
            }, {
                value: 0
            }],
            opacity: [
                {
                    value: 0,
                    duration: 50
                },
                {
                    value: 1,
                    duration: 300
                }, {
                    value: 0,
                    duration: 200
                }
            ],
            duration: 1000,
            loop: false,
            easing: 'easeInOutQuad'
        });

        confetti.finished.then(function() {
            emoji.remove();
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init(){
        if(!this.logo) return;
        this.mouseEnter();
        this.mouseLeave();
    }

}