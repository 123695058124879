import anime from 'animejs';

/** ----------------------------------------
    Enlarge Images
 ---------------------------------------- */

/**
 * The class adds interaction to the My DDW
 * related pages and templates.
 */

export class EnlargeImage {

    /**
     * @init            calls the init method
     */

    constructor(image) {
        this.active = false;
        this.image = image;
        this.init();
    }

    /** ----------------------------------------
        Click Event
     ---------------------------------------- */

    clickEvent(image) {
        image.addEventListener('click', (e) => {
            let src = image.dataset.enlarge;
            this.openPopup(src);
        });
    }

    openPopup(image) {
        this.active = true;

        let html = `
            <div class="lightbox">
                <img src="${ image }">
            </div>
        `;

        document.body.insertAdjacentHTML('beforeend', html);

        let popup = document.querySelector('.lightbox');

        this.animateOpen(popup);
        this.closePopup(popup);
    }

    animateOpen(popup) {
        anime({
            targets: popup,
            opacity: 1,
            scale: 1,
            duration: 225,
            easing: 'easeOutQuad'
        });
    }

    closePopup(popup) {
        popup.addEventListener('click', (e) => {
            this.animateClose(popup);
        });
    }

    animateClose(popup) {
        let close = anime({
            targets: popup,
            opacity: 0,
            scale: 1.05,
            duration: 225,
            easing: 'easeOutQuad'
        });

        close.finished.then(function() {
            popup.remove();
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.clickEvent(this.image);
    }

}