/** ----------------------------------------
     Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
     Slides
 ---------------------------------------- */

const fadeIn = (el, duration = 500, delay = 0) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [0, 1],
            duration: duration,
            delay: delay,
            easing: 'easeInOutSine',
            begin: () => resolve()
        });
    });
};

const fadeOut = (el, duration = 500, delay = 0) => {
    return new Promise((resolve) => {
        anime({
            targets: el,
            opacity: [1, 0],
            duration: duration,
            delay: delay,
            easing: 'easeInOutSine',
            complete: () => resolve()
        });
    });
};

/** ----------------------------------------
     Letters
 ---------------------------------------- */

const lettersIn = (...els) => {
    return new Promise((resolve) => {
        anime({
            targets: [...els],
            translateY: [30, 0],
            rotate: [-5, 0],
            opacity: [0, 1],
            delay: (el, i) => 25 + 25 * i,
            duration: 350,
            easing: 'easeOutCirc',
            complete: () => resolve()
        });
    });
};

const lettersOut = (...els) => {
    return new Promise((resolve) => {
        anime({
            targets: [...els],
            opacity: [1, 0],
            delay: (el, i) => 50 + (60 * i),
            duration: 250,
            easing: 'easeOutQuad',
            complete: () => resolve()
        });
    });
};

/** ----------------------------------------
     Exports
 ---------------------------------------- */

export {
    lettersIn,
    lettersOut,
    fadeIn,
    fadeOut
};