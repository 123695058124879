/**
 * Vruchtvlees © 2007-2019
 *
 * Hi, welcome!
 *
 * This is the main Javscript file.
 * One script to rule them all!
 * Let's go and godspeed you!
 */

/** ----------------------------------------
    Webpack Settings
 ---------------------------------------- */

let webpack = SETTINGS;

/** ----------------------------------------
    Environment
 ---------------------------------------- */

/**
 * This class will check the current
 * environment of the website and stores
 * an object with information about the
 * current environment.
 */

import { Environment } from '@helper/Environment.js';

const host = new Environment({
    localhost: 'vv-boilerplate',
    preview: 'vv-boilerplate',
    webpack: webpack
});

/** ----------------------------------------
     Log
 ---------------------------------------- */

import { Log } from '@helper/Log.js';

const log = new Log(host);

if(!host.info.environment.live) {
    log.section('VV', 'Environment');
    host.log();
}

/** ----------------------------------------
     Custom Router
 ---------------------------------------- */

// import { Router } from '@js/module/Router';
// new Router('#js-content');

/** ----------------------------------------
    Sticky Navigation
 ---------------------------------------- */

import { StickyNavigation } from '@js/module/StickyNavigation';

new StickyNavigation();

/** ----------------------------------------
    Mobile Menu
 ---------------------------------------- */

import { MobileMenu } from '@js/module/MobileMenu';
new MobileMenu();

/** ----------------------------------------
     Canvas
 ---------------------------------------- */

require('./canvas.js');

/** ----------------------------------------
    Popup Templates
 ---------------------------------------- */

import { thanks } from '@js/templates/message';

/** ----------------------------------------
    Popup
 ---------------------------------------- */

import { Popup } from './module/Popup';

new Popup({
    popup: '#js-popup-advice',
    open: '.js-advice'
});

new Popup({
    popup: '#js-popup-message',
    open: '#js-message',
    html: thanks('Bedankt', 'We hebben je aanvraag ontvangen'),
    enlarge: false
});

/** ----------------------------------------
     Header
 ---------------------------------------- */

import { Header } from '@js/module/Header';
new Header();

/** ----------------------------------------
     Images
 ---------------------------------------- */

import { Images } from '@js/module/Images';
new Images();

/** ----------------------------------------
     Quotes
 ---------------------------------------- */

import { Quotes } from '@js/module/Quotes';
new Quotes();

/** ----------------------------------------
     Video
 ---------------------------------------- */

import { Video } from '@js/module/Video';

for (let videoEl of document.querySelectorAll('.video')) {
    new Video(videoEl);
}

/** ----------------------------------------
    Article Feed
 ---------------------------------------- */

import { ArticleFeed } from '@js/module/ArticleFeed';
new ArticleFeed();

/** ----------------------------------------
    Select
 ---------------------------------------- */

import { Select } from '@js/module/Select';

const selectElems = document.querySelectorAll('.select');

for (let selectEl of selectElems) {
    new Select(selectEl, host);
}

/** ----------------------------------------
    Advice Form
 ---------------------------------------- */

import { RecaptchaForm } from '@js/module/RecaptchaForm';

const adviceFormNode = document.querySelector('#advice-form');
const popupAdviceContentNode = document.querySelector('#popup-advice-content');

if (adviceFormNode) {
    const adviesFormRedirect = adviceFormNode.dataset.redirect;

    const adviceRecaptchaForm = new RecaptchaForm({
        form: adviceFormNode,
        action: 'advice',
    });

    adviceRecaptchaForm.init();

    adviceRecaptchaForm.success = (data) => {
        if (adviesFormRedirect) {
            window.location.replace(adviesFormRedirect);
        } else {
            popupAdviceContentNode.innerHTML = data;
        }
    };
}

/** ----------------------------------------
    Contact Form
 ---------------------------------------- */

const contactSuccessNode = document.querySelector('.js-contact-success');
const contactDescNode = document.querySelector('.js-contact-desc');
const contactFormNode = document.querySelector('#contact-form');

if (contactFormNode) {
    const contactFormRedirect = contactFormNode.dataset.redirect;

    const contactRecaptchaForm = new RecaptchaForm({
        form: contactFormNode,
        action: 'contact',
    });

    contactRecaptchaForm.init();

    contactRecaptchaForm.success = () => {
        if (contactFormRedirect) {
            window.location.replace(contactFormRedirect);
        } else {
            contactFormNode.parentNode.removeChild(contactFormNode);
            contactSuccessNode.style.display = 'block';
            contactDescNode.style.display = 'none';
        }
    };
}

/** ----------------------------------------
    Custom Forms
 ---------------------------------------- */

for (const customFormFormNode of document.querySelectorAll('#custom-form')) {
    const customFormSuccessNode = customFormFormNode.parentNode.querySelector('.js-custom-form-success');

    const customFormRedirect = customFormFormNode.dataset.redirect;

    const customFormRecaptchaForm = new RecaptchaForm({
        form: customFormFormNode,
        action: 'custom',
    });

    customFormRecaptchaForm.init();

    customFormRecaptchaForm.success = (data) => {
        if (customFormRedirect) {
            window.location.replace(customFormRedirect);
        } else {
            if (data.type === 'mail') {
                customFormFormNode.parentNode.removeChild(customFormFormNode);
                customFormSuccessNode.style.display = 'block';
            }

            if(data.type === 'mailchimp') {
                customFormFormNode.parentNode.removeChild(customFormFormNode);
                customFormSuccessNode.style.display = 'block';
                customFormSuccessNode.innerHTML = `<p>${ data.response }</p>`;
            }
        }
    };
}

/** ----------------------------------------
    Newsletter Form
 ---------------------------------------- */

import { Form } from '@js/module/Form';

const newsletterMessageNode = document.querySelector('.js-newsletter-message');
const newsletterFormNode = document.querySelector('#newsletter-form');

if (newsletterFormNode) {
    const newsletterForm = new Form({
        form: newsletterFormNode,
    });

    newsletterForm.init();
    newsletterForm.success = (data) => {
        newsletterMessageNode.style.display = 'block';
        newsletterMessageNode.innerHTML = data.response;

        if (!data.error) {
            newsletterFormNode.parentNode.removeChild(newsletterFormNode);
        }
    };
}

/** ----------------------------------------
    Scroll to
 ---------------------------------------- */

import { ScrollTo } from '@js/module/ScrollTo.js';
const anchors = document.querySelectorAll('.js-scroll');

for (let anchor of anchors) {
    const attr = anchor.dataset.scrollTo;
    const target = document.getElementById(attr);

    anchor.addEventListener('click', (e) => {
      e.preventDefault();

      target.scrollIntoView({behavior: "smooth", block: "start"})
    });

    // new ScrollTo(anchor, 1500);
}

/** ----------------------------------------
     Gallery
 ---------------------------------------- */

import { Gallery } from '@js/module/Gallery';

const galleryBlock = document.querySelectorAll('.gallery-block');

[...galleryBlock].map(el => {
    new Gallery({
        el: el,
        holder: '.gallery-block__slider',
        caption: '.gallery-block__caption',
        counter: '.gallery-block__count',
        arrows: {
            next: '#next',
            prev: '#prev'
        }
    });
});

const infoSection = document.querySelectorAll('.info__gallery');

[...infoSection].map(el => {
    new Gallery({
        el: el,
        holder: '.info__slider',
        arrows: {
            next: '#next',
            prev: '#prev'
        }
    });
});

/** ----------------------------------------
    Slider & Filter
 ---------------------------------------- */

import { SliderFilter } from '@js/module/SliderFilter';

const sliderFilterElems = document.querySelectorAll('.js-slider-filter');

[...sliderFilterElems].map(el => {
    new SliderFilter(el);
});

/** ----------------------------------------
    Tabs
 ---------------------------------------- */

import { Tabs } from '@js/module/Tabs';

const tabElems = document.querySelectorAll('.js-tabs');

[...tabElems].map(el => {
    new Tabs(el);
});

/** ----------------------------------------
     Label
 ---------------------------------------- */

import { Label } from '@js/module/Label';

const formSelectors = [
    '#advice-form',
    '#newsletter-form',
    '#contact-form',
    '#custom-form',
];

for (const el of document.querySelectorAll(formSelectors.join(','))) {
    new Label(el);
}

/** ----------------------------------------
     Social Share
 ---------------------------------------- */

import SocialShare from '@js/module/SocialShare';

const share = document.querySelectorAll('[data-module="social"]');
[...share].map(el => new SocialShare(el));

/** ----------------------------------------
    Accordion
 ---------------------------------------- */

import { Accordion } from '@js/module/Accordion';
const accordions = document.querySelectorAll('.accordion-block__holder');

for (let accordion of accordions) {
    if (accordion) {
        new Accordion({
            holder: accordion,
            trigger: '.accordion-block__question',
            target: '.accordion-block__content'
        });
    }
}

/** ----------------------------------------
    Enlarge Image
 ---------------------------------------- */

import { EnlargeImage } from './module/EnlargeImage';

const images = document.querySelectorAll('.js-enlarge');

for (let image of images) {
    if(image) new EnlargeImage(image);
}

/** ----------------------------------------
     Animated Buttons
 ---------------------------------------- */

const buttons = document.querySelectorAll('.buttons__item');
import * as transition from '@js/transitions/illustrations';

if(buttons.length) {
    [...buttons].map(button => {
        const type = button.dataset.icon;

        const enterButton = () => transition[type].play();
        const leaveButton = () => transition[type].leave();

        button.addEventListener('mouseenter', enterButton, false);
        button.addEventListener('mouseleave', leaveButton, false);
    });
}

/** ----------------------------------------
     Error 404
 ---------------------------------------- */

import { Error } from '@module/Error';
new Error();

/** ----------------------------------------
    Easter Egg
 ---------------------------------------- */

/**
 * Easter comes early this year, enjoy!
 */

import { Confetti } from '@js/style/easter/Confetti';
new Confetti();
