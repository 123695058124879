/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Transitions
 ---------------------------------------- */

import * as transition from '@js/transitions/gallery';

/** ----------------------------------------
    Canvas Defaults
 ---------------------------------------- */

let count = 0;
let mobile = false;

/** ----------------------------------------
    Canvas
 ---------------------------------------- */

const canvas = document.querySelector('#js-canvas');

if(canvas) {

    /** ----------------------------------------
        Header
     ---------------------------------------- */

    const header = document.querySelector('.header__canvas');
    const button = header.querySelector('.header__hub-button');
    const credits = header.querySelector('.header__hub-credits');
    const caption = header.querySelector('.header__hub-caption');

    const slides = JSON.parse(canvas.dataset.slides).filter(slide => {
        return (slide.image);
    });

    const amount = slides.length;

    /** ----------------------------------------
        Mobile
     ---------------------------------------- */

    const setActive = () => mobile = window.innerWidth < 640;
    window.addEventListener('resize', setActive);
    setActive();

    const retina = () => mobile ? 2 : 1;

    /** ----------------------------------------
        Canvas
     ---------------------------------------- */

    const c = canvas.getContext('2d');

    canvas.width = window.innerWidth * retina();
    canvas.height = window.innerHeight * (mobile ? 0.5 : 0.7) * retina();

    window.addEventListener('resize', () => {
        canvas.width = window.innerWidth * retina();
        canvas.height = window.innerHeight * (mobile ? 0.5 : 0.7) * retina();
    });

    let zoom = {
        x: 0,
        y: 0
    };

    /** ----------------------------------------
        Logo
     ---------------------------------------- */

    const logoEl = document.querySelector('.canvas__logo');

    let logo = {};

    const calcLogo = () => {
        logo = {
            width: logoEl.clientWidth,
            height: logoEl.clientHeight,
        };
    };

    calcLogo();

    /** ----------------------------------------
        Background
     ---------------------------------------- */

    const calcRatio = (array) => {
        let min = Math.min(...array);
        return array.map(element => element/min);
    };

    const calcImage = (image) => {
        let width = 0;
        let height = 0;

        let valueX = 0;
        let valueY = 0;

        let centerWidth = 0;
        let centerHeight = 0;

        const dim = image.height / image.width > 1 ? 'portrait' : 'landscape';

        const imageRatio = calcRatio([image.width, image.height]);
        const canvasRatio = calcRatio([canvas.width, canvas.height]);

        if(imageRatio[0] > canvasRatio[0] || imageRatio[1] > canvasRatio[1]) {
            width = canvas.height * Math.max(...imageRatio);
            height = canvas.height;

            centerWidth = 0;
            centerHeight = (height - canvas.height) / 2;
        } else {
            width = canvas.width;
            height = canvas.width / Math.max(...imageRatio);

            centerWidth = (width - canvas.width) / 2;
            centerHeight = 0;
        }

        valueX = width;
        valueY = height;

        return {
            width: {
                center: centerWidth,
                value: width,
                init: image.width
            },
            height: {
                center: centerHeight,
                value: height,
                init: image.height
            },
            canvas: {
                width: canvas.width,
                height: canvas.height
            },
            valueX: valueX,
            valueY: valueY,
            ratio: imageRatio,
            type: dim
        };
    };

    /** ----------------------------------------
        Image Selector
     ---------------------------------------- */

    let image = new Image();

    let calc = {
        width: {
            center: 0,
            value: 0,
            init: 0
        },
        height: {
            center: 0,
            value: 0,
            init: 0
        },
        canvas: {
            width: canvas.width,
            height: canvas.height
        },
        valueX: 100,
        valueY: 100,
        ratio: 0,
        type: ''
    };

    const setImage = () => {
        if(count === amount) count = 0;

        image.src = slides[count].image;

        image.addEventListener('load', () => {
            calc = calcImage(image);
        });
    };

    /** ----------------------------------------
        Values
     ---------------------------------------- */

    let values = {};

    const calcValues = () => {
        values = {
            x: (canvas.width / 2) - logo.width / 2 + 32,
            y: (canvas.height / 2) - logo.height / 2 + logo.height - 32,
            width: calc.width.value,
            height: calc.height.value,
            radius: 0,
            color: '#0039D8'
        };
    };

    calcValues();

    /** ----------------------------------------
        Animation
     ---------------------------------------- */

    let animation = true;

    anime({
        targets: values,
        radius: window.innerWidth * 1.25,
        duration: (window.innerHeight / 5) * 20,
        delay: 1000,
        easing: 'easeOutExpo',
        loop: true,
        loopComplete: () => {
            values.radius = 0;
            animation = !animation;

            if(animation) {
                count += count <= (amount) ? 1 : count - amount;

                zoom.x = 0;
                zoom.y = 0;

                transition.fadeIn(credits, 500, 350);
                transition.fadeIn(caption, 500, 500);
                transition.fadeIn(button, 500, 650);

                setImage();
            } else {
                const next = count === amount - 1 ? 0 : count + 1;

                transition.fadeOut(credits, 500, 500)
                    .then(() => {
                        credits.textContent = slides[next].credits;
                    });

                transition.fadeOut(caption, 500, 650)
                    .then(() => {
                        caption.textContent = slides[next].caption;
                    });

                transition.fadeOut(button, 500, 800)
                    .then(() => {
                        button.href = slides[next].link;

                        slides[next].link
                            ? button.classList.add('is-active')
                            : button.classList.remove('is-active');
                    });
            }
        }
    });

    if (amount) setImage();

    /** ----------------------------------------
        Resize
     ---------------------------------------- */

    window.addEventListener('resize', () => {
        calcImage(image);
        calcLogo();
        calcValues();
    });

    /** ----------------------------------------
        Render
     ---------------------------------------- */

    const ease = t => t*t;

    const draw = () => {
        if (animation) {
            c.save();
            c.beginPath();
            c.arc(values.x, values.y, values.radius * retina(), 0, Math.PI * 2);
            c.clip();
            c.drawImage(image, calc.width.center - (zoom.x * ease(zoom.x) / 2), calc.height.center - (zoom.y * ease(zoom.y) / 2), calc.width.value + zoom.x * ease(zoom.x), calc.height.value + zoom.y * ease(zoom.y));
            c.restore();
            c.closePath();
        }

        if (!animation) {
            c.save();
            c.beginPath();
            c.rect(0, 0, window.innerWidth * retina(), window.innerHeight * retina());
            c.clip();
            c.drawImage(image, calc.width.center - (zoom.x * ease(zoom.x) / 2), calc.height.center - (zoom.y * ease(zoom.y) / 2), calc.width.value + zoom.x * ease(zoom.x), calc.height.value + zoom.y * ease(zoom.y));
            c.restore();
            c.closePath();
            c.beginPath();
            c.arc(values.x, values.y, values.radius * retina(), 0, Math.PI * 2);
            c.fillStyle = values.color;
            c.fill();
            c.closePath();
        }

        if(!mobile) {
            zoom.x += 0.009;
            zoom.y += 0.009;
        }
    };

    /** ----------------------------------------
        Refresh Animation
     ---------------------------------------- */

    const animate = () => {
        requestAnimationFrame(animate);
        c.clearRect(0, 0, canvas.width, canvas.height);
        draw();
    };

    if (amount) animate();
}