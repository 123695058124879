/** ----------------------------------------
     Images
 ---------------------------------------- */

export class Images {

    constructor() {
        this._active = true;

        this._images = document.querySelectorAll('.image-block--crop .image-block__image, .gallery-block__image');

        if(!this._images) return;

        this.init();
    }

    /** ----------------------------------------
        Scroll
     ---------------------------------------- */

    _scroll() {
        window.addEventListener('scroll', () => {
            if(!this._active) return;

            [...this._images].forEach(item => {
                if(item.classList.contains('js-blocked')) return;

                if(this._inView(item)) {
                    const bounding = item.getBoundingClientRect();
                    const top = Math.abs((bounding.top - window.innerHeight) * 0.025);
                    item.style.top = `calc(50% + ${ top }px)`;
                }
            });
        });
    }

    /** ----------------------------------------
         View
     ---------------------------------------- */

    _inView(el) {
        const bounding = el.getBoundingClientRect();
        return bounding.top - window.innerHeight < 0 && bounding.bottom > 0;
    }

    /** ----------------------------------------
         Prevent Mobile
     ---------------------------------------- */

    _mobile() {
        const setActive = () => this._active = window.innerWidth > 1080;
        window.addEventListener('resize', setActive);
        setActive();
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        this._mobile();
        this._scroll();
    }
}