/* ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';

/* ----------------------------------------
    Timeline
---------------------------------------- */

const animateItems = anime.timeline({
    easing: 'easeOutQuad',
    autoplay: false,
    duration: 650
});

animateItems
    .add({
        targets: '.mobile__item',
        scale: [1.15, 1],
        opacity: [0, 1],
        duration: 500,
        easing: 'easeOutQuad',
        delay: anime.stagger(50)
    })
    .add({
        targets: '.mobile__sub-menu',
        opacity: [0, 1],
    }, 250)
    .add({
        targets: '.mobile__social',
        opacity: [0, 1],
    }, 450);

/* ----------------------------------------
    Animations
---------------------------------------- */

const openMenu = el => anime({
    targets: el,
    opacity: [0, 1],
    scale: [1.1, 1],
    easing: 'easeOutQuad',
    duration: 350,
    begin: () => {
        animateItems.restart();
        document.body.style.overflow = 'hidden';
        el.style.display = 'flex';
    }
});

const closeMenu = el => anime({
    targets: el,
    opacity: [1, 0],
    scale: [1, 1.1],
    easing: 'easeOutQuad',
    duration: 350,
    complete: () => {
        document.body.style.overflow = 'auto';
        el.style.display = 'none';
    }
});

/* ----------------------------------------
    Mobile Menu
---------------------------------------- */

export class MobileMenu {

    constructor() {
        this.active = false;
        this._init();
    }

    _open() {
        this._setState(true);
        openMenu(this._mobile);
    }

    _close() {
        this._setState(false);
        closeMenu(this._mobile);
    }

    _setState(bool) {
        const hamburger = this._hamburger;
        const icon = hamburger.childNodes[1];
        const type = bool ? 'add' : 'remove';
        icon.classList[type]('is-active');
    }

    _event() {
        this._hamburger.addEventListener('click', () => {
            this.active = !this.active;
            this.active ? this._open() : this._close();
        });
    }

    _init() {
        this._mobile = document.querySelector('.mobile');
        if(!this._mobile) return;

        this._hamburger = document.querySelector('.nav__hamburger');
        this._event();
    }

}
